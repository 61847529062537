<template>
  <div class="upLoadCertificate">
    <div class="minTitle">上传凭证</div>
    <div class="tips"><van-icon name="info-o" />转账后，请上传以下资料图片，要求上传的图片清晰无遮挡、无涂改、图片大小不大于8M。</div>
    <div class="upLoadCon">
    <p class="wtTest">1、打款凭证（可多张）</p>
      <div class="cardBox">
        <!-- <img :src="item.viewPath" v-for="(item,index) in imgList" :key="index" v-if="item" /> -->
        <div class="imagebox" v-for="(item,index) in imgList" :key="index" v-if="item">
          <div class="imageItem" v-loading="loading[index]" element-loading-text="正在上传"
               element-loading-spinner="el-icon-loading"
               element-loading-background="rgba(0, 0, 0, 0.5)">
            <img :src="item.imgUrl?item.imgUrl:item.viewPath" class="avatar">
            <div class="modal" @click.stop="() => {}" v-if="!loading[index]">
              <!-- <div class="delete" @click.stop="handleRemove(index)">
                <img src="../assets/img/close-white.png" alt="">
              </div> -->
            </div>
          </div>
        </div>
        <div class="imagebox">
          <el-upload class="uploadMore" action="#" :auto-upload="false" :show-file-list="false"
                     :on-change="(f)=>changeFile(f,imgList.length)"
                     element-loading-text="正在上传"
                     element-loading-spinner="el-icon-loading"
                     element-loading-background="rgba(0, 0, 0, 0.5)">
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </div>
     <!--  <div class="subtitle">
        <div class="line"></div>
        <div class="text">资料上传说明</div>
        <div class="line"></div>
      </div> -->
      <!-- <div class="desc black">个人非身份证用户换卡资料：</div> -->
      <!-- <div class="desc gray">用户本人手持身份证原件（头像面）、手持身份证原件反面（国徽面）照片，不可镜像拍照，且身份证上的字务必清晰</div> -->
      <div class="button wtBtn" @click="sub">提交</div>
    </div>
  </div>
</template>
<script>
import compressFile from '../utils/compress.js';
import { submitNewCard, upload, getImgAuditInfo } from "../services/proApi";

let Base64 = require('js-base64').Base64;
export default {
  data() {
    return {
      imgList: [],
      loading: [],
      operator:'',
      imgList:[]
    };
  },
  created: function() { 
    this.operator = localStorage.getItem("mobilePhone");
    this.businessId = this.$route.query.reserveId;
    this.getImgAuditInfo()
  },
  methods: {
    handleRemove(index) {
      this.imgList.splice(index, 1);
    },
    async sub() {
      if (!this.imgList.length) {
        this.$toast('请先上传凭证');
        return;
      }else{
        this.$router.replace({
          path: '/signList',
          query: { }
        });
      }
    },
    async getImgAuditInfo() {
      let businessId = this.businessId
      const data = await getImgAuditInfo({businessId});
      this.imgList = data.data.voucherUrls
    },
    async changeFile(file, index) {
      console.log(file,index)
      // return false;
      const isJPEG = file?.raw.type === 'image/jpeg';
      const isPNG = file?.raw.type === 'image/png';
      const isJPG = file?.raw.type === 'image/jpg';
      const isLt2M = file?.raw.size / 1024 / 1024 < 8;
      if (!isJPG && !isJPEG && !isPNG) {
        this.$message.error('上传头像图片只能是 JPG, PNG 格式!');
        return;
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 8MB!');
        return;
      }
      this.$set(this.loading, index, true);
      let operator = this.operator,businessId = this.businessId
      this.compressImageSize(file.raw, async (files, base64, before) => {
        this.$set(this.imgList, index, { imgUrl: base64 });
        console.log(base64)
        let base64Arr = base64.split('base64,')
        const { status, data } = await upload({
          base64Img: base64Arr[1],
          operator:operator,
          businessId:businessId,
          fileName:file?.name
        });
        this.$set(this.loading, index, false);
        if (+data.code === 1) {
          this.$set(this.imgList, index, data.data);
        }
      });
    },
    compressImageSize(file, finish) {
      compressFile(file, 0.8, async (files, base64, before) => {
        if (files.size / 1024 / 1024 < .8) {
          finish(files, base64, before);
        } else {
          this.compressImageSize(files, finish);
        }
      });
    }
  }
};

</script>
<style lang="scss" scoped>
.upLoadCertificate {
  padding-bottom: 5rem;
  .upLoadCon {
    background: #fff;
    padding: 1.5rem 0 8rem 0;
    margin: 1.5rem 0 0;
  }
  .upLoadCon .wtTest{padding:0 0 0 10px;font-size:12px;}
  .cardBox {
    margin: 1.3rem 3%;
  }

  .imagebox {
    width: 50%;
    display: inline-block;

    margin-bottom: 1rem;
    box-sizing: border-box;

    &:nth-child(2n-1) {
      padding-right: 0.5rem;
    }

    &:nth-child(2n) {
      padding-left:0.5rem;
    }
  }

  .imageItem, .uploadMore {
    width: 100%;
    height: 7rem;
    vertical-align: middle;
    display: inline-block;
    position: relative;
  }

  .imageItem {
    .avatar {
      width: 100%;
      height: 100%;
    }
  }

  .uploadMore {
    background-color: #fff;
    text-align: center;
    font-size: 0.9rem;

    .avatar-uploader-icon {
      font-size: 3rem;
      color: #8c939d;
      width: 100%;
      height: 100%;
      line-height: 7rem;
      text-align: center;
    }
  }
  .upload {
    margin: 1.3rem 3%;
    background-color: #fff;
    text-align: center;
    font-size: 0.9rem;
    .avatar-uploader-icon {
      font-size:3rem;
      color: #8c939d;
      width: 100%;
      height: 100%;
      line-height: 15rem;
      text-align: center;
    }

    .avatar {
      height: 70rem;
      width: auto;
      display: block;
      margin: 0 auto;
    }
  }

  .minTitle {
    margin:1.3rem 3%;
    box-sizing: border-box;
    font-size: 1.2rem;
  }

  .subtitle {
    margin-top: 4rem;
    margin-bottom: 1rem;
    width: 100%;
    padding: 0 6%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    .line {
      height:0.01rem;
      flex: 1;
      background-color: #000;
    }

    .text {
      padding: 0 .8rem;
      font-size:0.9rem;
    }
  }
  .desc {
    padding: 0 6%;
    line-height:2.2rem;
  }
  .gray {
    color: #666;
  }
  .tips {
    padding: 0 3%;
    color: #ff0000;
    font-size: 0.8rem;
    i{font-size: 0.9rem;}
  }
  .modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .delete {
    width: 3rem;
    height: 3rem;
    position: absolute;
    right: 0;
    top: 0;
    background-color: #5063f3;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 0 1rem;
    img {
      width: 2rem;
      height: 2rem;
      display: block;
    }
  }
  .wtBtn{margin-top:12rem}
}
</style>
