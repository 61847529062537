function compressFile(file, rate, callback) {
  let fileObj = file;
  let rt = rate || 0.5;
  let reader = new FileReader();
  reader.readAsDataURL(fileObj); //转base64
  reader.onload = function(e) {
    let image = new Image(); //新建一个img标签（还没嵌入DOM节点)
    image.src = e.target.result;
    image.onload = function() {
      let canvas = document.createElement('canvas'), // 新建canvas
        context = canvas.getContext('2d'),
        imageWidth = this.width / 2,    //压缩后图片的大小
        imageHeight = this.height / 2,
        data = '';
      canvas.width = imageWidth;
      canvas.height = imageHeight;
      context.drawImage(this, 0, 0, imageWidth, imageHeight);
      data = canvas.toDataURL('image/jpeg', rt); // 输出压缩后的base64,rate为压缩比，0-1之间
      let arr = data.split(','), mime = arr[0].match(/:(.*?);/)[1], // 转成blob
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      let files = new window.File([new Blob([u8arr], { type: mime })], 'test.jpeg', { type: 'image/jpeg' }); // 转成file
      callback(files, data, e.target.result); // 回调
    };
  };
}

export default compressFile;
